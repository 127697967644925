<template>
  <div class="container">
    <el-row>
      <content-title :nav="nav"></content-title>
    </el-row>
    <el-row>
      <div style="background-color: white">
        <el-button type="primary" style="padding: 1rem 2.5rem; margin-bottom: 1rem" @click="openDialog()">添加学龄类目
        </el-button>
        <el-table :data="typeList" :header-cell-class-name="'table-header'" v-loading="is_loading" row-key="id"
          :tree-props="{ hasChildren: 'hasChildren', children: 'childList' }">
          >
          <el-table-column label="ID" prop="id"></el-table-column>
          <el-table-column label="名称" prop="name"> </el-table-column>
          <el-table-column label="年龄范围" prop="name">
            <template slot-scope="scope">
              {{ scope.row.start_age + "-" + scope.row.end_age }}
            </template>
          </el-table-column>

          <el-table-column label="拥有类目" prop="names"> </el-table-column>

          <el-table-column label="添加时间" prop="create_time" width="200px"></el-table-column>
          <el-table-column label="来源" prop="source"></el-table-column>
          <el-table-column label="更新时间" prop="update_time" width="200px"></el-table-column>

          <el-table-column label="操作" prop="" width="280px">
            <template slot-scope="scope">
              <el-button type="primary" size="small" @click="edit(scope.row)">编辑</el-button>
              <el-button type="success" size="small" @click="openBind(scope.row)">绑定</el-button>
              <el-button type="danger" size="small" @click="if_delete(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination :total="typeTotal" :size="10" :current-page="currentPage" @current-change="changeCurrentPage">
        </el-pagination>
        <!-- 确认删除 -->
        <el-dialog title="提示" :visible.sync="confirmDelete" width="20%" :before-close="handleClose"
          :append-to-body="true">
          <div style="width: 100%; text-align: center">确认删除?</div>
          <span slot="footer" class="" style="display: flex; justify-content: space-around">
            <el-button type="danger" @click="deleteType()">确 定</el-button>
            <el-button type="primary" @click="handleClose">取 消</el-button>
          </span>
        </el-dialog>
        <!-- 新增 -->
        <el-dialog title="添加类目" :visible.sync="addVisible" :width="ks?'95%':'35%'" :before-close="handleClose">
          <div class="dialog-body">
            <el-form :model="form" id="new-good-form" :rules="rules">
              <el-form-item label-width="8rem" label="类目名称" prop="name">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label-width="8rem" label="年龄范围" prop="start_age">
                <label for="起始年龄">起始年龄</label>
                <el-input name="起始年龄" v-model="form.start_age" :placeholder="temp.start_age" style="width:50px !important;"></el-input>
                <label for="结束年龄">结束年龄</label>
                <el-input name="结束年龄" v-model="form.end_age" :placeholder="temp.end_age" style="width:50px !important;"></el-input>
              </el-form-item>
              <el-form-item label-width="8rem" label="类目图片" prop="name">
                <avatar-uploader @getImgUrl="getCover" :clear="if_clear"></avatar-uploader>
              </el-form-item>
              <!-- <el-form-item label-width="8rem" label="类目名称" prop="name">
                <el-select v-model="form"></el-select>
              </el-form-item> -->
              <!-- <el-form-item label-width="8rem" label="排序" prop="categories_child">
                <el-input v-model="form.weights"></el-input>
              </el-form-item>
              <el-form-item label-width="8rem" label="图片">
                <avatar-uploader @getImgUrl="getAvatarUrl" :clear="if_clear"></avatar-uploader>
              </el-form-item> -->
            </el-form>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary" @click="addNewType()">确 定</el-button>
          </span>
        </el-dialog>

        <!-- 修改类目 -->
        <el-dialog title="修改类目" :visible.sync="updateVisible" :width="ks?'95% ':'35%'" :before-close="handleClose">
          <div class="dialog-body">
            <el-form :model="form" id="new-good-form" :rules="rules">
              <el-form-item label-width="8rem" label="类目名称" prop="name">
                <el-input v-model="form.name" :placeholder="temp.name"></el-input>
              </el-form-item>
              <el-form-item label-width="8rem" label="年龄范围" prop="start_age">
                <el-input label="起始年龄" v-model="form.start_age" :placeholder="temp.start_age"></el-input>
                <el-input label="结束年龄" v-model="form.end_age" :placeholder="temp.end_age"></el-input>
              </el-form-item>
              <el-form-item label-width="8rem" label="类目图片" prop="name">
                <avatar-uploader @getImgUrl="getCover" :url="form.url"></avatar-uploader>
              </el-form-item>
              <el-form-item label-width="8rem" label="排序" prop="categories_child">
                <el-input v-model="form.weights" :placeholder="temp.weights"></el-input>
              </el-form-item>
              <el-form-item label-width="8rem" label="图片">
                <avatar-uploader @getImgUrl="getAvatarUrl" :clear="if_clear" :url="temp.pic"></avatar-uploader>
              </el-form-item>
            </el-form>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary" @click="update()">确 定</el-button>
          </span>
        </el-dialog>

        <!-- 绑定子类目 -->
        <el-dialog title="绑定科目" :visible.sync="bindVisible" :width="ks?'95%':'35%'" :before-close="handleClose">
          <div class="dialog-body">
            <el-transfer filterable filter-placeholder="请选择绑定的科目" v-model="haveCate" :data="categories_list" :props="{
                key: 'name',
                label: 'name',
              }" :titles="['所有类目', '拥有类目']">
            </el-transfer>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary" @click="bind()">确 定</el-button>
          </span>
        </el-dialog>
      </div>
    </el-row>
  </div>
</template>

<script>
import avatarUploader from '../../components/avatarUploader.vue'
export default {
  components: {
    avatarUploader,
  },
  data() {
    return {
      ks:'',
      nav: { firstNav: '体育宝管理中心', secondNav: '年龄段管理' },
      temp: {},
      is_loading: false,
      if_clear: false,
      addVisible: false,
      updateVisible: false,
      bindVisible: false,
      deleteId: null,
      confirmDelete: false,
      currentPage: 1,
      typeTotal: 0,
      typeList: [],
      categories_list: [],
      form: {},
      rules: {},
      haveCate: [],
    }
  },
  computed: {},
  methods: {
    getCover(v) {
      this.form.url = v
    },
    changeCurrentPage(v) {
      this.currentPage = v
    },
    getTypeList() {
      this.is_loading = true
      let url = '/user/userServiceAge/queryManagerListPage'
      this.$axios
        .get(url, {
          params: {
            status: 2,
            pageSize: 99,
            currentPage: 1,
            platform: '学龄',
          },
        })
        .then((res) => {
          this.typeList = res.data.data.rows

          this.typeTotal = res.data.data.total
          this.is_loading = false
        })
    },

    if_delete(row) {
      this.temp = row
      this.confirmDelete = true
    },
    deleteType() {
      let url = '/user/mechanismCategory/update'
      this.$axios
        .post(url, {
          id: this.temp.id,
          status: 1,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: 'warning', message: '删除成功!' })
            this.confirmDelete = false
            this.getTypeList()
          }
        })
    },
    update() {
      let url = '/user/mechanismCategory/update'
      this.$axios
        .post(url, {
          id: this.temp.id,
          start_age: this.form.start_age ? this.form.start_age : null,
          end_age: this.form.end_age ? this.form.end_age : null,
          name: this.form.name ? this.form.name : null,
          pic: this.form.url ? this.form.url : null,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: 'success', message: '更新成功!' })
            this.updateVisible = false
            this.getTypeList()
          }
        })
    },
    edit(row) {
      this.updateVisible = true
      this.temp = row
    },

    getAvatarUrl(v) {
      this.form.pic = v
    },
    handleClose(done) {
      this.if_clear = false
      this.$confirm('确认关闭？')
        .then((_) => {
          this.addVisible = false
          this.updateVisible = false
          this.confirmDelete = false
          this.bindVisible = false
          this.form = {}
          this.if_clear = true
          done()
        })
        .catch((_) => {})
    },
    openBind(row) {
      this.temp = row
      this.haveCate = []
      if (this.temp.names) {
        let name_list = this.temp.names.split(',')
        for (let i = 0; i < name_list.length; i++) {
          if (name_list[i] != 'undefined') {
            this.haveCate.push(name_list[i])
          }
        }
      }
      console.log(this.haveCate)
      this.bindVisible = true
    },
    bind() {
      let names = ''
      console.log(this.haveCate)
      for (let i = 0; i < this.haveCate.length; i++) {
        if (i != this.haveCate.length - 1 && this.haveCate[i] != 'undefined') {
          names = names + this.haveCate[i] + ','
        } else {
          names = names + this.haveCate[i]
        }
      }
      let url = '/user/mechanismCategory/update'
      this.$axios
        .post(url, {
          id: this.temp.id,
          names,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: 'success', message: '绑定成功!' })
            this.getTypeList()
            this.bindVisible = false
          }
        })
    },
    openDialog() {
      // this.form = {};
      this.addVisible = true
    },
    addNewType() {
      this.if_clear = false
      let url = '/user/userServiceAge/insert'
      this.$axios
        .post(url, {
          name: this.form.name ? this.form.name : null,
          platform: '学龄',
          weight: 1,
          status: 2,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: 'success', message: '添加成功!' })
            this.form = {}
            this.if_clear = true
            this.addVisible = false
            this.getTypeList()
          } else {
            this.$message({ type: 'error', message: res.data.message })
          }
        })
    },
    getCategoriesList() {
      let url = '/user/mechanismCategory/queryManagerListPage'
      this.$axios
        .get(url, {
          params: {
            status: 2,
            type: 2,
            source: '体育宝',
          },
        })
        .then((res) => {
          this.categories_list = res.data.data.rows
        })
    },
  },
  mounted() {
    this.getTypeList();
    this.getCategoriesList();
    this.ks=window.screen.width<768
  },
}
</script>

<style></style>
